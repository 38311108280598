import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import ParticipateBayerTrial from '../components/participate-bayer-trial/pbt';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col } from 'reactstrap';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import {
  Fonts,
  Container,
  AfterTrialSection,
  WhatToExpectPage,
  StyledTooltip,
} from '../global.css';

class WhatToExpect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipCard: this.props.data.whatToExpectEnJson.afterTrialCards.map(
        () => false
      ),
    };
  }

  flipCard = index => {
    let flipCard = this.state.flipCard;
    flipCard[index] = !flipCard[index];
    this.setState({ flipCard: flipCard });
  };

  render() {
    let { data } = this.props;
    return (
      <Layout meta={data.whatToExpectEnJson.seo}>
        <Fonts>
          <WhatToExpectPage>
            <Container>
              <div className="fix-padding">
                <h2 className={'main-title'}>
                  {data.whatToExpectEnJson.infoTitle}
                </h2>
                <div className={'research-staff'}>
                  <p>
                    {data.whatToExpectEnJson.infoContent}
                    <span className="fa fa-info-circle" id="info-icon" />
                  </p>
                  <StyledTooltip
                    trigger="hover"
                    placement="top"
                    target="info-icon"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.whatToExpectEnJson.infoPopover
                            .childMarkdownRemark.html,
                      }}
                    />
                  </StyledTooltip>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={data.whatToExpectEnJson.pdfLink}
                  >
                    {null}
                  </a>
                </div>
                <h2 className={'main-title'}>
                  {data.whatToExpectEnJson.expectTitle}
                </h2>
                <div className={'step-section'}>
                  {data.whatToExpectEnJson.steps.map((item, index) => (
                    <div
                      key={index}
                      className={'step-article' + ` item-${index}`}
                    >
                      <div className={`step-arrow item-${index}`}>
                        {data.whatToExpectEnJson.stepArrowText}
                        <strong>{item.arrowNumber}</strong>
                      </div>
                      <div
                        className={'step-text'}
                        dangerouslySetInnerHTML={{
                          __html: item.content.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <AfterTrialSection>
                <h2 className={'main-title'}>
                  {data.whatToExpectEnJson.afterTrialTitle}
                </h2>
                <Row>
                  {data.whatToExpectEnJson.afterTrialCards.map(
                    (item, index) => (
                      <Col key={index} md={6} lg={6} xl={3}>
                        <div className={`card-container card-${index}`}>
                          <div
                            className={
                              !this.state.flipCard[index]
                                ? 'after-trial-card'
                                : 'after-trial-card flip-card flipped'
                            }
                          >
                            <div className={`front-card card-${index}`}>
                              <FaPlusCircle
                                className={'corner-icon'}
                                onClick={() => {
                                  this.flipCard(index);
                                }}
                              />
                              <Img
                                className={'card-icon'}
                                fluid={item.icon.childImageSharp.fluid}
                                alt={item.title}
                              />
                              <h3>{item.title}</h3>
                            </div>
                            <div className={`back-card card-${index}`}>
                              <FaTimesCircle
                                className={'corner-icon'}
                                onClick={() => {
                                  this.flipCard(index);
                                }}
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content.childMarkdownRemark.html,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </AfterTrialSection>
              <ParticipateBayerTrial />
            </Container>
          </WhatToExpectPage>
        </Fonts>
      </Layout>
    );
  }
}
WhatToExpect.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhatToExpect;

export const query = graphql`
  query WhatToExpect {
    whatToExpectEnJson {
      title
      infoTitle
      infoContent
      infoPopover {
        childMarkdownRemark {
          html
        }
      }
      pdfLink
      expectTitle
      stepArrowText
      steps {
        arrowNumber
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      afterTrialTitle
      afterTrialCards {
        title
        icon {
          childImageSharp {
            fluid(maxHeight: 104, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
